@import "@Assets/styles/themedStyles.scss";
.venues-spaces {
  max-width: 1440px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  &-items {
    border-radius: 8px;
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 30px;
    width: 100%;
    &-item {
      display: flex;
      width: 100%;
      gap: 20px;
      &-left {
        display: flex;
        flex-direction: column;
        width: 390px;
      }
      &-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        &-top {
          display: flex;
          width: 100%;
          gap: 20px;
          flex: 1;
          &-calendar {
            flex: 1;
          }
          &-selectors {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 70px;
          }
        }
        &-banner {
          background-color: #e9ecef;
          padding: 15px;
          border-radius: 8px;
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          margin: 20px 0px 30px 0px;
          width: fit-content;
          margin-left: auto;
          &-link {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        &-cta {
          align-self: flex-end;
          min-width: 190px;
        }
      }
      &-title {
        font-size: 32px;
        line-height: 38px;
        font-weight: 600;
        padding: 0 15px;
        min-height: 38px;
        width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-image {
        width: 100%;
        min-height: 240px;
        max-height: 240px;
        margin-top: 10px;
        object-fit: cover;
      }
      &-description {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        padding: 20px;
        height: 100%;
      }
      &-button {
        padding: 20px 20px 0 20px;
      }
      &-thumbnails {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        gap: 5px;
        flex-wrap: wrap;
        max-height: 300px;
        overflow-y: auto;
        &-thumbnail {
          width: 120px;
          height: 80px;
        }
      }
    }
  }
  &-selected {
    border-radius: 0;
  }

  &-bottom {
    border-top: solid $defaultSecondaryColor 1px;
    background-color: #fff;
    padding: 20px 0;
    &-items {
      display: flex;
      padding: 20px 0;
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .venues-spaces {
    &-items {
      &-item {
        flex-direction: column;
        &-left {
          height: 100%;
          width: 65vw;
        }
        &-right {
          &-top {
            flex-direction: column;
            &-selectors {
              margin-top: 0px;
            }
          }
          &-cta {
            margin-top: 30px;
            min-width: 190px;
            align-self: normal;
          }
        }
        &-title {
          font-size: 24px;
          line-height: 30px;
          padding: 0;
        }
        &-image {
          max-height: 170px;
          min-height: 170px;
          margin-top: 10px;
        }
        &-description {
          padding: 20px 5px;
          text-align: justify;
        }
        &-button {
          padding: 20px;
        }
        &-thumbnails {
          &-thumbnail {
            width: 140px;
            height: 85px;
          }
        }
      }
    }
    &-bottom {
      &-items {
        padding-left: 20px;
      }
    }
  }
}
