.dropdown-menu-container-center {
  position: relative;
}
.dropdown-menu-container-right {
  position: absolute;
  right: 0;
  width: 100%;
  top: 30px;
}
.dropdown-menu {
  background-color: #e7e7e7;
  position: absolute;
  z-index: 5;
  min-width: 200px;
  min-height: 100px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown-menu-center {
  top: 10px;
  max-width: 342px;
  width: 100%;
}

.dropdown-menu-right {
  top: 25px;
  right: 30px;
}

.dropdown-menu-item:not(:first-of-type) {
  padding-top: 10px;
  box-shadow: inset 0px -2px 0px #c4c4c4;
}
