@import "@Assets/styles/themedStyles.scss";

.nav-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $navigationBackgroundColor;

  .nav-wrapper {
    z-index: 101;
    box-sizing: border-box;
    margin: 0;
    padding: 0 10px;
    background-color: $navigationBackgroundColor;
    width: 100%;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    @media only screen and (max-width: 768px) {
      padding: 0;
    }

    &-hidden {
      display: none;
    }

    .nav {
      position: relative;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: stretch;
      min-height: 60px;

      &-logo-container {
        display: flex;
        align-items: center;

        .nav-logo-link {
          display: flex;
          align-items: center;
          cursor: pointer;

          &-banner {
            height: auto;
            width: 100%;
            @media only screen and (max-width: 768px) {
              display: none;
            }
          }

          &-icon {
            height: 40px;
            width: 40px;
            //object-fit: cover;
            display: none;
            @media only screen and (max-width: 768px) {
              display: block;
              margin-left: 15px;
            }
          }
        }
      }

      &-menu-items-center {
        display: flex;
        gap: 20px;
        align-items: stretch;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }

      &-menu-items-right {
        display: flex;
        gap: 20px;
        @media only screen and (max-width: 768px) {
          display: none;
          &.visible {
            display: flex;
            gap: 0;
            min-width: 100vw;
            top: 70px;
            position: absolute;
            flex-direction: column;
          }
        }

        .user-welcome {
          display: none;
          @media only screen and (max-width: 768px) {
            display: block;
            padding: 20px;
            font-weight: 700;
            color: $userMenuTextColor;
            font-size: 14px;
            line-height: 17px;
            text-align: left;
            border-bottom: 2px solid #dae4f8;
          }
        }

        .mobile-items {
          display: none;
          @media only screen and (max-width: 768px) {
            display: block;
            border-bottom: 2px solid #dae4f8;
            padding: 20px;
          }
        }

        .main-items {
          display: flex;
          gap: 15px;
          @media only screen and (max-width: 768px) {
            flex-direction: column;
            padding: 20px;
          }

          .nav-button-icon-link {
            display: flex;
            align-items: center;
            gap: 15px;
            text-decoration: none;
            color: $navigationLinkTextColor;

            &-text {
              font-size: 14px;
              line-height: 17px;
              font-weight: 700;
              display: none;
              color: $navigationLinkTextColor;
              @media only screen and (max-width: 768px) {
                display: block;
              }
            }
          }

          .nav-user-actions {
            display: flex;
            align-items: center;
            gap: 15px;

            @media only screen and (max-width: 768px) {
              flex-direction: column;
            }

            .nav-user-notifications-modal {
              @media only screen and (max-width: 768px) {
                display: none;
              }

              .nav-notifications-container {
                background-color: #f1f3f5;
                padding: 16px;

                .notification-item:first-of-type {
                  border-radius: 8px 8px 0 0;
                }

                .notification-item:last-of-type {
                  border-radius: 0 0 8px 8px;
                }

                &__bottom {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 19px;
                  text-decoration-line: underline;
                  color: #000000;
                  margin: 26px 0px 10px 0px;
                  cursor: pointer;
                }
              }
            }

            .nav-user-menu-modal {
              @media only screen and (max-width: 768px) {
                display: none;
              }
            }

            .nav-user-access-button {
              // Did this to use the existing Button component
              @media only screen and (min-width: 768px) {
                border: none;
                padding: 0;
                white-space: nowrap;
                color: $navigationLinkTextColor;
                background-color: $navigationBackgroundColor;
              }
            }

            .mobile-logout {
              display: none;
              @media only screen and (max-width: 768px) {
                display: block;
              }
            }
          }
        }
      }

      .nav-mobile-menu-button {
        display: none;
        border: none;
        background-color: transparent;
        width: auto;

        @media only screen and (max-width: 768px) {
          display: block;
        }
      }
    }
  }

  .nav-content-container {
    display: flex;
    height: 100%;
    min-width: 100vw;
    background-color: #eee;
    justify-content: center;

    .nav-content {
      text-align: left;
      height: auto;
      width: 100%;
      min-height: 98vh;
      max-width: 1440px;
    }

  }
}
