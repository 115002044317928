.generic-listing {
  cursor: default;
  &-header {
    padding: 30px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title {
      font-size: 48px;
      line-height: 57px;
    }
    &-search {
      min-width: 30%;
    }
  }
  &-listing {
    background-color: #fff;
    padding: 60px;
    &-fullscreen {
      min-height: 100vh;
    }
  }
  &-row {
    padding: 24px 0px;
    &-title {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 10px;
    }
    &-item {
      padding-top: 10px 0 0 0;
      border: none;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-image {
        width: 306px;
        height: auto;
      }
      &-image--small {
        width: 196px;
        height: auto;
      }
      &-title {
        font-size: 20px;
        line-height: 24px;
        padding: 20px 0 0 0;
        text-align: left;
      }
    }
    &-loader {
      display: flex;
      align-items: center;
      padding-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .generic-listing {
    &-header {
      padding: 15px 20px;
      display: flex;
      flex-direction: column;
      &-title {
        font-size: 30px;
        line-height: 47px;
      }
      &-search {
        padding-top: 5px;
        min-width: 90%;
      }
    }
    &-listing {
      padding: 10px 30px;
    }
    &-row {
      padding: 20px 0px;
      &-title {
        font-size: 23px;
      }
      &-item {
        &-title {
          padding-top: 15px;
          font-size: 17px;
        }
        &-image {
          width: 53vw;
          object-fit: cover;
        }
      }
    }
  }
}
